import { enableSplitMetricsList, enforceResourceNameLength } from '@gonfalon/dogfood-flags';
import { isEmpty, orderBy, sortBy } from '@gonfalon/es6-utils';
import { FilterableMetricDashboardItem, MetricKind, MetricListingRep } from '@gonfalon/metrics';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, Record, Set } from 'immutable';
import qs from 'qs';
import { v4 } from 'uuid';

import {
  ExperimentSummaryResultsType,
  ExperimentSummaryTotal,
  NumericWinner,
  SuccessCriteria,
} from 'components/FlagExperiments/types';
import { AccessChecks, allowDecision, createAccessDecision } from 'utils/accessUtils';
import { createMemberSummary, Member, MemberSummary } from 'utils/accountUtils';
import { getChangeValue } from 'utils/flagExperimentUtils';
import { CreateFunctionInput, ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import { makeFilter } from 'utils/stringUtils';
import { isAbsoluteURL } from 'utils/urlUtils';
import {
  equals,
  isLength,
  isNotEmpty,
  isValidEachKeyed,
  isValidTagList,
  optional,
  validateHttpOrHttps,
  validator,
} from 'utils/validationUtils';

export const MAX_METRIC_NAME_LENGTH = 256;

export const goalKinds = {
  PAGEVIEW: 'pageview',
  CLICK: 'click',
  CUSTOM: 'custom',
};

export const successCriterias = {
  HigherThanBaseline: {
    value: 'HigherThanBaseline',
    display: 'Higher than baseline',
  },
  LowerThanBaseline: {
    value: 'LowerThanBaseline',
    display: 'Lower than baseline',
  },
};

export const goalKindDisplayNames = {
  [goalKinds.PAGEVIEW]: 'Page view',
  [goalKinds.CLICK]: 'Click',
  [goalKinds.CUSTOM]: 'Custom',
};

export const metricDescriptions = {
  [goalKinds.PAGEVIEW]: 'track if an end user viewed a certain page',
  [goalKinds.CLICK]: 'track if an end user clicked on a certain target',
  [goalKinds.CUSTOM]: 'track other events by creating your own settings',
};

// TODO: remove this sort order post FF enabling https://launchdarkly.atlassian.net/browse/MTRX-919
export enum MetricSortOrder {
  TYPE = 'type',
  CREATION_ASC = 'createdOn',
  CREATION_DESC = '-createdOn',
  NAME_ASC = 'name',
  NAME_DESC = '-name',
  FLAGS_DESC = '-flags',
  FLAGS_ASC = 'flags',
}

export enum MetricSortOrderV2 {
  CREATION_DESC = '-createdAt',
  CREATION_ASC = 'createdAt',
  NAME_ASC = 'name',
  NAME_DESC = '-name',
  FLAGS_DESC = '-connectionCount',
  FLAGS_ASC = 'connectionCount',
}

// TODO: clean this type up https://launchdarkly.atlassian.net/browse/MTRX-919
export const metricSortOrderDisplayNames = {
  [MetricSortOrder.TYPE]: 'Type',
  [MetricSortOrder.CREATION_DESC]: 'Newest',
  [MetricSortOrder.CREATION_ASC]: 'Oldest',
  [MetricSortOrderV2.CREATION_DESC]: 'Newest',
  [MetricSortOrderV2.CREATION_ASC]: 'Oldest',
  [MetricSortOrder.NAME_ASC]: 'Name A-Z',
  [MetricSortOrder.NAME_DESC]: 'Name Z-A',
  [MetricSortOrder.FLAGS_DESC]: 'Most connections',
  [MetricSortOrder.FLAGS_ASC]: 'Fewest connections',
  [MetricSortOrderV2.FLAGS_DESC]: 'Most connections',
  [MetricSortOrderV2.FLAGS_ASC]: 'Fewest connections',
};

type MetricFiltersProps = {
  q: string;
  sort: string;
  type: string;
  tags: string[];
  maintainerIds?: string[];
  maintainerTeamKey: string;
  hasConnections: string | boolean | undefined | null;
};

export class MetricFilters extends Record<MetricFiltersProps>({
  q: '',
  sort: MetricSortOrder.CREATION_DESC,
  type: '',
  tags: [],
  maintainerIds: [],
  maintainerTeamKey: '',
  hasConnections: null,
}) {
  isEmpty() {
    return isEmpty(this.q) && isEmpty(this.type);
  }

  toQuery() {
    const ret: Partial<MetricFiltersProps> = {};
    if (!isEmpty(this.q)) {
      ret.q = this.q;
    }

    if (!isEmpty(this.type)) {
      ret.type = this.type;
    }

    if (!isEmpty(this.tags)) {
      ret.tags = this.tags;
    }

    if (!isEmpty(this.maintainerIds)) {
      ret.maintainerIds = this.maintainerIds;
    }

    if (!isEmpty(this.maintainerTeamKey)) {
      ret.maintainerTeamKey = this.maintainerTeamKey;
    }

    if (!isEmpty(this.hasConnections)) {
      ret.hasConnections = this.hasConnections;
    }

    if (!isEmpty(this.sort) && this.sort !== MetricSortOrder.CREATION_DESC) {
      ret.sort = this.sort;
    }

    return ret;
  }

  toSearchParams() {
    const searchParams = new URLSearchParams();
    searchParams.set('q', this.q);
    searchParams.set('sort', this.sort);
    searchParams.set('type', this.type);

    const tagsArray = typeof this.tags === 'string' ? [this.tags] : Array.isArray(this.tags) ? this.tags : [];

    if (tagsArray.length > 0) {
      tagsArray.forEach((tag) => {
        searchParams.append('tags', tag);
      });
    }
    if (this.maintainerIds && this.maintainerIds.length > 0) {
      // If multiple maintainer IDs, join with comma for URL serialization
      searchParams.set(
        'maintainerIds',
        Array.isArray(this.maintainerIds) ? this.maintainerIds.join(',') : this.maintainerIds,
      );
    } else {
      searchParams.delete('maintainerIds');
    }
    searchParams.set('maintainerTeamKey', this.maintainerTeamKey);
    if (this.hasConnections !== null && this.hasConnections !== undefined) {
      if (typeof this.hasConnections === 'boolean') {
        searchParams.set('hasConnections', this.hasConnections ? 'true' : 'false');
      } else {
        // It's already a string
        searchParams.set('hasConnections', String(this.hasConnections));
      }
    } else {
      searchParams.delete('hasConnections');
    }

    return searchParams;
  }

  toHistorySearch() {
    return qs.stringify(this.toQuery(), { indices: false });
  }

  setTags(tags: string | string[]) {
    const normalizedTags = typeof tags === 'string' ? [tags] : Array.isArray(tags) ? tags : [];
    return this.set('tags', normalizedTags);
  }

  setMaintainerIds(maintainerIds: string | string[]) {
    return this.set('maintainerIds', Array.isArray(maintainerIds) ? maintainerIds : [maintainerIds]);
  }

  setMaintainerTeamKey(maintainerTeamKey: string) {
    return this.set('maintainerTeamKey', maintainerTeamKey);
  }

  setHasConnections(hasConnections: boolean | undefined) {
    return this.set('hasConnections', hasConnections);
  }
}

export function createMetricFilters(props: CreateFunctionInput<MetricFilters> = {}) {
  return props instanceof MetricFilters ? props : new MetricFilters(fromJS(props));
}

export const defaultMetricSortOrder = enableSplitMetricsList() ? MetricSortOrderV2.CREATION_DESC : MetricSortOrder.TYPE;

export function createCombinedMetricFilters(props: CreateFunctionInput<MetricFilters> = {}) {
  if (!props.sort) {
    const propsWithDefaultSort = { sort: defaultMetricSortOrder, ...props };
    return new MetricFilters(fromJS(propsWithDefaultSort));
  }
  return props instanceof MetricFilters ? props : new MetricFilters(fromJS(props));
}

export enum UrlMatcherKind {
  SIMPLE = 'canonical',
  EXACT = 'exact',
  REGEX = 'regex',
  SUBSTRING = 'substring',
}

export const urlMatcherKindDisplayNames = {
  [UrlMatcherKind.SIMPLE]: 'Simple match',
  [UrlMatcherKind.EXACT]: 'Exact match',
  [UrlMatcherKind.REGEX]: 'Regular expression',
  [UrlMatcherKind.SUBSTRING]: 'Substring match',
};

export const matcherKeyProp = '_key';

const getMatcherKey = (m: URLMatcher) => m[matcherKeyProp];

const kindsWithMatchers = ['pageview', 'click'];

const urlMatcherKindValueMap: { [s: string]: 'url' | 'pattern' | 'substring' } = {
  [UrlMatcherKind.SIMPLE]: 'url',
  [UrlMatcherKind.EXACT]: 'url',
  [UrlMatcherKind.REGEX]: 'pattern',
  [UrlMatcherKind.SUBSTRING]: 'substring',
};

const validateExactURLMatcher = validator(
  equals(UrlMatcherKind.EXACT)('kind'),
  isNotEmpty('url'),
  isAbsoluteURL('url'),
  validateHttpOrHttps('url'),
);

const validateCanonicalURLMatcher = validator(
  equals(UrlMatcherKind.SIMPLE)('kind'),
  isNotEmpty('url'),
  isAbsoluteURL('url'),
  validateHttpOrHttps('url'),
);

const validateRegexURLMatcher = validator(equals(UrlMatcherKind.REGEX)('kind'), isNotEmpty('pattern'));

const validateSubstringURLMatcher = validator(equals(UrlMatcherKind.SUBSTRING)('kind'), isNotEmpty('substring'));

function validateURLMatcher(matcher: URLMatcherProps) {
  const validators = {
    [UrlMatcherKind.SIMPLE]: validateCanonicalURLMatcher,
    [UrlMatcherKind.EXACT]: validateExactURLMatcher,
    [UrlMatcherKind.REGEX]: validateRegexURLMatcher,
    [UrlMatcherKind.SUBSTRING]: validateSubstringURLMatcher,
  };

  return validators[matcher.kind](matcher);
}

const validateCustomGoal = validator(
  isNotEmpty('name'),
  enforceResourceNameLength() ? isLength(1, MAX_METRIC_NAME_LENGTH)('name') : () => undefined,
  optional(isValidTagList)('tags'),
  isNotEmpty('eventKey'),
);

export const detectDuplicateMatchers = ({ urls }: { urls: URLMatcher[] }) => {
  let filtered = Set();
  let nonNullCount = 0;
  urls.forEach((url) => {
    const { kind: urlMatcherKind } = url;
    const urlMatcherValue = url[urlMatcherKindValueMap[urlMatcherKind]].trim().toLowerCase();
    if (urlMatcherValue) {
      filtered = filtered.add(`${urlMatcherKind}${urlMatcherValue}`);
      nonNullCount++;
    }
  });
  if (filtered.size !== nonNullCount) {
    return { for: 'urls', errors: ['You cannot have duplicate URL matchers'] };
  }
};

const validatePageviewGoal = validator(
  isNotEmpty('name'),
  enforceResourceNameLength() ? isLength(1, MAX_METRIC_NAME_LENGTH)('name') : () => undefined,
  optional(isValidTagList)('tags'),
  isNotEmpty('urls'),
  isValidEachKeyed(getMatcherKey, validateURLMatcher, 'urls'),
  detectDuplicateMatchers,
);

const validateClickGoal = validator(
  isNotEmpty('name'),
  enforceResourceNameLength() ? isLength(1, MAX_METRIC_NAME_LENGTH)('name') : () => undefined,
  optional(isValidTagList)('tags'),
  isNotEmpty('selector'),
  isNotEmpty('urls'),
  isValidEachKeyed(getMatcherKey, validateURLMatcher, 'urls'),
  detectDuplicateMatchers,
);

const goalValidatorByKind = {
  [goalKinds.CUSTOM]: validateCustomGoal,
  [goalKinds.PAGEVIEW]: validatePageviewGoal,
  [goalKinds.CLICK]: validateClickGoal,
};

export function validateGoal(goal: Goal) {
  return goalValidatorByKind[goal.kind](goal.toJS());
}

export type URLMatcherProps = {
  _key: string;
  kind: UrlMatcherKind;
  url: string;
  pattern: string;
  substring: string;
};

export class URLMatcher extends Record<URLMatcherProps>({
  _key: '',
  kind: UrlMatcherKind.SIMPLE,
  url: '',
  pattern: '',
  substring: '',
}) {
  getValueName() {
    return urlMatcherKindValueMap[this.kind];
  }

  getValue() {
    const prop = this.getValueName();
    return this.get(prop);
  }

  toRep() {
    return this.withMutations((map) => {
      map.remove('_key');

      if ([UrlMatcherKind.EXACT, UrlMatcherKind.SIMPLE].includes(this.kind)) {
        map.remove('pattern');
        map.remove('substring');
      }

      if (this.kind === UrlMatcherKind.REGEX) {
        map.remove('url');
        map.remove('substring');
      }

      if (this.kind === UrlMatcherKind.SUBSTRING) {
        map.remove('url');
        map.remove('pattern');
      }
    });
  }
}

export type AssociatedFlagProps = {
  _links: ImmutableMap<{ self: Link }>;
  _site: ImmutableMap<{ href: Link }>;
  key: string;
  name: string;
  archived?: boolean;
  onClickDetailsLink?: () => void;
};

export class AssociatedFlag extends Record<AssociatedFlagProps>({
  _links: Map(),
  _site: Map(),
  key: '',
  name: '',
  archived: false,
}) {
  selfLink() {
    return this._site.get('href');
  }
}

export type MetricAttachedExperiment = {
  key: string;
  name: string;
  environmentId: string;
  creationDate: number;
  archivedDate?: number;
  onClickDetailsLink?: () => void;
  _links: ImmutableMap<{ parent: Link; self: Link }>;
};

export type GoalProps = {
  description: string;
  isActive: boolean;
  kind: string;
  name: string;
  selector: string;
  urls: List<URLMatcher>;
  _access: AccessChecks;
  _attachedFlagCount: number;
  _attachedFeatures: List<AssociatedFlag>;
  experimentCount?: number;
  experiments: List<ImmutableMap<MetricAttachedExperiment>>;
  _id: string;
  _isDeleteable: boolean;
  _links: ImmutableMap<{ self: Link }>;
  _site: Link;
  _source: ImmutableMap<{}>;
  _version: number;
  lastModified: string;
  creationDate: string;
  _creationDate: number;
  key: string;
  eventKey: string;
  isNumeric: boolean;
  unit: string;
  successCriteria?: SuccessCriteria;
  maintainerId?: string;
  _maintainer?: MemberSummary;
  tags: Set<string>;
  randomizationUnits: Set<string>;
};

export class Goal extends Record<GoalProps>({
  description: '',
  isActive: true,
  kind: '',
  name: '',
  selector: '',
  urls: List(),
  _access: Map(),
  _attachedFlagCount: 0,
  _attachedFeatures: List(),
  experimentCount: 0,
  experiments: List(),
  _id: '',
  _isDeleteable: true,
  _links: Map(),
  _site: Map({ href: '' }),
  _source: Map(),
  _version: 1,
  lastModified: '',
  creationDate: '',
  _creationDate: 0,
  key: '',
  eventKey: '',
  isNumeric: false,
  unit: '',
  successCriteria: undefined,
  maintainerId: undefined,
  _maintainer: undefined,
  tags: Set(),
  randomizationUnits: Set(),
}) {
  validate() {
    return fromJS(validateGoal(this));
  }

  hasGoal() {
    return this._links.has('goal');
  }

  selfLink() {
    return this._links.getIn(['self', 'href']);
  }

  isDeleteable() {
    return this._isDeleteable;
  }

  siteLink() {
    return this._site.get('href');
  }

  getVersion() {
    return this._version;
  }

  checkAccess({ profile }: { profile: Member }) {
    const access = this.get('_access');

    if (profile.isReader()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Reader' });
    }

    if (profile.isAdmin() || profile.isWriter() || profile.isOwner() || !access || !access.get('denied')) {
      return allowDecision;
    }

    return (action: string) => {
      const deniedAction = access.get('denied').find((v) => v.get('action') === action);
      if (deniedAction) {
        const reason = deniedAction.get('reason');
        const roleName = reason && reason.get('role_name');
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }
      return createAccessDecision({ isAllowed: true });
    };
  }

  toRep() {
    return fromJS(this.toJSON()).withMutations((map: Goal) => {
      map.remove('_access');

      if (this.kind === goalKinds.PAGEVIEW) {
        map.remove('selector');
        map.remove('eventKey');
        map.remove('isNumeric');
      }

      if (this.kind === goalKinds.CLICK) {
        map.remove('eventKey');
        map.remove('isNumeric');
      }

      if (this.kind === goalKinds.CUSTOM) {
        map.remove('urls');
        map.remove('selector');
      }

      if (this.kind === goalKinds.PAGEVIEW || this.kind === goalKinds.CLICK) {
        map.update('urls', (urls) => urls.map((matcher) => matcher.toRep()));
      }
    });
  }

  getAttachedFlagCount() {
    return this._attachedFlagCount;
  }

  getAttachedExperimentCount() {
    return this.experimentCount ?? 0;
  }
}

export function matcherValidationKey(matcher: URLMatcher) {
  if (!matcher) {
    // this happens as outlined in CH1703; returning an empty string skirts around the problem and avoids a js error
    // TODO: make sure we only trigger one or the other of the createGoalForm and editGoalForm reducers, not both at the same time
    return '';
  }
  return `urls.${matcher.get(matcherKeyProp)}`;
}

// this is how we determine which url is used for the click goal editor.
// it returns the matcher and the rest of the urls
export function partitionURLMatchersWithDefault(goal: Goal) {
  const urls = goal.get('urls');
  const matcher = urls.find((m) => m.get('kind') === 'exact');
  const index = matcher ? urls.indexOf(matcher) : -1;
  return { matcher, index, rest: urls.splice(index, 1) };
}

export function createAssociatedFlag(props: CreateFunctionInput<AssociatedFlag>) {
  return props instanceof AssociatedFlag ? props : new AssociatedFlag(fromJS(props));
}

export function createURLMatcher(props: CreateFunctionInput<URLMatcher>) {
  const matcher = props instanceof URLMatcher ? props : new URLMatcher(fromJS(props));
  return matcher.update('_key', (key) => (key.length === 0 ? v4() : key));
}

export function createGoal(props: CreateFunctionInput<Goal> = {}) {
  let goal = props instanceof Goal ? props : new Goal(fromJS(props));

  if (kindsWithMatchers.includes(goal.kind)) {
    goal = goal.update('urls', (us) => us.map(createURLMatcher));
  }

  goal = goal.withMutations((g) => {
    // v1 custom goals have null unit and successCriteria, so we massage it to return defaults here
    g.update('unit', (u) => u || '');

    g.update('successCriteria', (sc) => {
      if (typeof sc !== 'undefined' || g.kind !== 'custom') {
        return sc;
      }

      if (g.isNumeric) {
        return SuccessCriteria.LOWER_THAN_BASELINE;
      }

      return SuccessCriteria.HIGHER_THAN_BASELINE;
    });

    g.update('_attachedFeatures', (f) => {
      // v1 endpoint returns [], v2 returns null when there's no attached flags.
      if (f) {
        return f.map(createAssociatedFlag);
      }
      return List();
    });

    if (g.tags) {
      g.update('tags', (tags) => tags.toSet());
    }

    if (g._maintainer) {
      g.update('_maintainer', (m) => createMemberSummary(m));
    }
  });

  return goal;
}

export function makeDefaultURLMatcher() {
  return createURLMatcher({ kind: UrlMatcherKind.SIMPLE, url: '' });
}

export function filterMetrics(metrics: MetricListingRep[], filters: MetricFilters) {
  const textFilter = filters.get('q');
  const typeFilter = filters.get('type');
  const sortOrder = filters.get('sort');
  const byText = makeFilter(textFilter, 'name', 'description');
  const sorted = sortBy(
    metrics.filter(byText).filter((m) => (!!typeFilter ? m.kind === typeFilter : true)),
    (m) => {
      switch (sortOrder) {
        case MetricSortOrder.CREATION_ASC:
        case MetricSortOrder.CREATION_DESC:
          return m._creationDate;
        case MetricSortOrder.NAME_ASC:
        case MetricSortOrder.NAME_DESC:
          return m.name.toLowerCase();
        case MetricSortOrder.FLAGS_ASC:
        case MetricSortOrder.FLAGS_DESC:
          return (m._attachedFlagCount ?? 0) + (m.experimentCount ?? 0);
        default:
          return -m._creationDate;
      }
    },
  );
  if (sortOrder.startsWith('-')) {
    return sorted.reverse();
  }
  return sorted;
}

function filterByType(metric: FilterableMetricDashboardItem, type: string | undefined) {
  if (!type) {
    return true;
  }
  if (metric.kind === type) {
    return true;
  }
  if (metric.kind === MetricKind.CUSTOM) {
    return (
      (type === MetricKind.NUMERIC && metric.isNumeric === true) ||
      (type === MetricKind.BINARY && metric.isNumeric !== true)
    );
  }
}

export function filterMetricsAndGroups(metrics: FilterableMetricDashboardItem[], filters: MetricFilters) {
  const textFilter = filters.get('q');
  const typeFilter = filters.get('type');
  const sortOrder = filters.get('sort') ?? '';
  const byText = makeFilter(textFilter, 'name', 'description');
  const filtered = metrics.filter(byText).filter((m) => filterByType(m, typeFilter));
  if (sortOrder === MetricSortOrder.TYPE) {
    return orderBy(filtered, ['type', '_creationDate'], ['desc', 'desc']);
  } else {
    const sorted = sortBy(filtered, (m) => {
      switch (sortOrder) {
        case MetricSortOrder.CREATION_ASC:
        case MetricSortOrder.CREATION_DESC:
          return m._creationDate;
        case MetricSortOrder.NAME_ASC:
        case MetricSortOrder.NAME_DESC:
          return m.name.toLowerCase();
        case MetricSortOrder.FLAGS_ASC:
        case MetricSortOrder.FLAGS_DESC:
          return (m._attachedFlagCount ?? 0) + (m.experimentCount ?? 0);
        default:
          return -m._creationDate;
      }
    });

    if (sortOrder.startsWith('-')) {
      return sorted.reverse();
    }
    return sorted;
  }
}

export const getNumericWinners = ({
  variationIndices,
  experimentSummaryResults,
  successCriteria,
  baselineIdx,
}: {
  variationIndices: number[];
  experimentSummaryResults: ExperimentSummaryResultsType;
  successCriteria?: SuccessCriteria;
  baselineIdx: number;
}) => {
  const winningObj: { [s: number]: NumericWinner } = {};
  variationIndices.forEach((vi) => {
    const winnerIndexChange: number | null = getChangeValue(experimentSummaryResults.totals, baselineIdx, vi, true);
    if (winnerIndexChange !== null) {
      if (
        (successCriteria === SuccessCriteria.LOWER_THAN_BASELINE && winnerIndexChange < 0) ||
        (successCriteria === SuccessCriteria.HIGHER_THAN_BASELINE && winnerIndexChange > 0)
      ) {
        winningObj[vi] = NumericWinner.IS_NUMERIC_SUCCESS;
      } else if (
        (successCriteria === SuccessCriteria.HIGHER_THAN_BASELINE && winnerIndexChange < 0) ||
        (successCriteria === SuccessCriteria.LOWER_THAN_BASELINE && winnerIndexChange > 0)
      ) {
        winningObj[vi] = NumericWinner.IS_NUMERIC_SETBACK;
      } else if (winnerIndexChange === 0) {
        winningObj[vi] = NumericWinner.IS_NUMERIC_NO_CHANGE;
      }
    }
  });
  return winningObj;
};

export const getVariationsWithStatisticalSignificance = (
  experimentSummaryResultTotals: ExperimentSummaryTotal[],
  baselineIndex: number,
) => {
  const variationIndices: number[] = [];
  experimentSummaryResultTotals.forEach((total, index) => {
    if (total.pValue !== null && total.pValue < 0.05 && index !== baselineIndex) {
      variationIndices.push(index);
    }
  });
  return variationIndices;
};
